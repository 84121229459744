<template>
  <div class="article__page-quote mt-5">
    <div class="article__page-img">
      <img src="" alt="">
    </div>
    <div class="article__page-content">
      <h2 class="title fs-40 fw-500 lh-12 mb-3 pb-1">“{{ item.title }}”</h2>
      <template v-if="isInObj('text', item)">
        <p class="text tc-silver fs-22 fw-400 mb-5">{{ item.text }}</p>
      </template>
      <p class="text tc-smoke fs-20 fw-400">– {{ item.author }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  methods: {
    isInObj (val, obj) {
      const isIn = val in obj
      let res = false

      if (isIn) {
        res = obj[val] !== undefined && obj[val].length > 0
      }
      return res
    }
  }
}
</script>
