<template>
  <div class="article__page-section">
    <div class="article__page-content">
      <template v-if="isInObj('title', item)">
        <h1 class="title fs-36 fs-md-40 fw-500 mb-3 pb-1">{{ item.title }}</h1>
      </template>
      <template v-if="isInObj('text', item)">
        <!-- <p class="text tc-silver fs-16 fs-md-20 fw-300 lh-17 mb-4">{{ item.text }}</p> -->
        <p class="text tc-silver fs-16 fs-md-20 fw-300 lh-17 mb-4" v-html="item.text"></p>
      </template>
      <template v-if="isInObj('img', item)">
        <template v-if="item.img !== ''">
          <div class="py-4 mb-5">
            <div class="article__page-img">
              <img :src="item.img" class="" alt="">
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  methods: {
    isInObj (val, obj) {
      return val in obj
    }
  }
}
</script>
