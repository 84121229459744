<template>
  <div class="page page--article">
    <div class="article">
      <div class="article__page">
        <article-content v-if="!isFetching" :content="getAvailableLocale(articleContent.content)" :type="cc.articleTypes[articleContent.type]"/>
      </div>
    </div>
    <section class="section" data-sec="last">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-8 offset-lg-2">
            <div class="share">
              <div class="share__wrapper">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="wrapper d-flex align-items-center">
                      <p class="text fs-20 fw-500">Share this article:</p>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="share__buttons">
                      <a :href="shareLink('tw')" class="share__link share__link--tw" @click.prevent @click="openModalShare"></a>
                      <a :href="shareLink('fb')" class="share__link share__link--fb" @click.prevent @click="openModalShare"></a>
                      <a :href="shareLink('vk')" class="share__link share__link--vk" @click.prevent @click="openModalShare"></a>
                      <a :href="shareLink('tg')" class="share__link share__link--tg" @click.prevent @click="openModalShare"></a>
                      <a :href="shareLink('em')" class="share__link share__link--em" @click.prevent @click="openModalShare"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<a href="https://twitter.com/intent/tweet?text=Virarosi%20%7C%20Blog&url=https://virarosi.com/en/">Test share TWITTER</a>
      <a href="https://www.facebook.com/sharer/sharer.php?u=https://virarosi.com/en/">Test share FACEBOOK</a>
      <a href="https://vk.com/share.php?url=https://virarosi.com/en/">Test share VK</a>
      <a href="https://t.me/share?url=https://virarosi.com/en/&text=Blog">Test share Telegram</a>
      <a href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site http://www.website.com.">Test send via email</a>-->
    </section>
  </div>
</template>
<script>
import ArticleContent from '@/components/article/ArticleContent'
// import ArticleQuote from '@/components/article/ArticleQuote'
export default {
  data: () => ({
    isFetching: true,
    xApiKey: 'PinealApiPass.1456',
    articleContent: [],
    articleSubject: '',
    shareLinksParams: {
      tw: {
        base: 'https://twitter.com/intent/tweet',
        params: ['url', 'text'],
        data: []
      },
      fb: {
        base: 'https://www.facebook.com/sharer/sharer.php',
        params: ['u'],
        data: []
      },
      vk: {
        base: 'https://vk.com/share.php',
        params: ['url'],
        data: []
      },
      tg: {
        base: 'https://t.me/share',
        params: ['url', 'text'],
        data: []
      },
      em: {
        base: 'mailto:',
        params: ['subject', 'body'],
        data: []
      }
    },
    tmp: {
      key: '',
      type: 'MN',
      date: '',
      content: {
        en: [
          {
            block: 'head',
            title: 'NVIDIA\'s 4 to 1 Stock Split Announced',
            text: 'Bringing Shares of Common Stock to 4 Billion',
            img: 'somepath'
          },
          {
            block: 'section',
            title: 'What is this?',
            text: 'This technology company is now labeled "Strong Buy" and up more than 4% since declaring this news. The process of stockholder approval for the stock dividend will be held virtually on 3rd June, at the company\'s 2021 Annual Meeting of Stockholders.'
          },
          {
            block: 'section',
            text: '1111The stock split is to make stocks more affordable to employees and retail investors. While some feel it\'s a risky move, Zacks has ranked NVIDIA #2 on their Buy List as it shows strong growth opportunities in various untapped markets like automotive, healthcare, and manufacturing over the long term.',
            img: 'article-content'
          },
          {
            block: 'section',
            title: 'What is this?',
            text: 'This technology company is now labeled "Strong Buy" and up more than 4% since declaring this news. The process of stockholder approval for the stock dividend will be held virtually on 3rd June, at the company\'s 2021 Annual Meeting of Stockholders.'
          },
          {
            block: 'section',
            text: 'The stock split is to make stocks more affordable to employees and retail investors. While some feel it\'s a risky move, Zacks has ranked NVIDIA #2 on their Buy List as it shows strong growth opportunities in various untapped markets like automotive, healthcare, and manufacturing over the long term.'
          },
          {
            block: 'quote',
            title: 'Virarosi is a powerful tool for managing crypto.',
            text: '',
            author: 'Jakub Cerny'
          }
        ]
      }
    }
  }),
  computed: {
    cc () {
      return this.$t('pages.blog')
    },
    locale () {
      return this.$i18n.locale
    },
    articleId () {
      return this.$route.params.id
    },
    articleUrl () {
      return window.location.href
    }
  },
  methods: {
    async getArticleContent () {
      this.articleContent = await fetch('/vendor/scripts/blog/article.php', {
        method: 'POST',
        headers: { 'X-Api-Key': this.xApiKey },
        body: JSON.stringify({ hash: this.articleId })
      })
        .then((response) => { if (response.ok) return response.json() })
        .then((json) => {
          return json.data[0]
        })
        .catch((error) => {
          console.log(error)
        })
      this.articleSubject = this.getAvailableLocale(this.articleContent.content)[0].title
      this.isFetching = false
      this.fillShareLinksData()
    },
    getAvailableLocale (obj) {
      return this.locale in obj ? obj[this.locale] : obj.en
    },
    shareLink (type) {
      const linkProps = this.shareLinksParams[type]
      let result = linkProps.base
      let splitter = '?'
      linkProps.params.forEach((item, i) => {
        if (i !== 0) splitter = '&'
        result += splitter + item + '=' + linkProps.data[i]
      })
      return result
    },
    fillShareLinksData () {
      for (const key of Object.keys(this.shareLinksParams)) {
        this.shareLinksParams[key].params.forEach(item => {
          switch (item) {
            case 'url':
            case 'u':
              this.shareLinksParams[key].data.push(this.articleUrl)
              break
            case 'text':
            case 'subject':
              this.shareLinksParams[key].data.push(this.articleSubject)
              break
            case 'body':
              this.shareLinksParams[key].data.push(this.articleSubject + ' ' + this.articleUrl)
              break
          }
        })
      }
    },
    openModalShare (event) {
      const href = event.target.href

      if (href.split(':')[0] !== 'mailto') {
        window.open(href, 'popup', 'width=600,height=600')
      } else {
        const mailtoTab = window.open(href, '_blank')
        setTimeout(() => {
          mailtoTab.close()
        }, 0)
      }
    }
  },
  components: {
    ArticleContent
  },
  metaInfo () {
    const meta = this.$t('meta.blog')
    return {
      titleTemplate: '%s | ' + this.articleSubject,
      meta: [
        { name: 'description', content: meta.description }
      ]
    }
  },
  mounted () {
    this.getArticleContent()
  }
}
</script>
