<template>
  <div class="article__page-header" :style="'background: url(' + item.img + ') center / cover no-repeat'">
    <div class="article__page-img">
      <img :src="item.img" alt="">
    </div>
    <div class="article__page-content">
      <p class="text tc-yellow fs-20 fw-500 mb-4">{{ type }}</p>
      <h1 class="title tc-white fs-36 fs-md-42 fw-600 lh-12 mb-4">{{ item.title }}</h1>
      <p class="text tc-white fs-16 fs-md-20 fw-400 lh-15">{{ item.text }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      required: true,
      type: String
    },
    item: {
      required: true,
      type: Object
    }
  }
}
</script>
