<template>
  <div>
    <section class="section" v-for="(item, i) in content" :key="'article-sec-' + i" :id="'sec-' + (i + 1)" :data-sec="(i + 1)">
      <div class="container">
        <div class="row">
          <template v-if="isItemBlock(item.block, 'head')">
            <div class="col-12">
              <article-header :item="item" :type="type" />
            </div>
          </template>
          <template v-if="isItemBlock(item.block, 'section')">
            <div class="col-lg-8 offset-lg-2">
              <article-section :item="item"/>
            </div>
          </template>
          <template v-if="isItemBlock(item.block, 'quote')">
            <div class="col-lg-8 offset-lg-2">
              <article-quote :item="item"/>
            </div>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import ArticleHeader from '@/components/article/ArticleHeader'
import ArticleSection from '@/components/article/ArticleSection'
import ArticleQuote from '@/components/article/ArticleQuote'
export default {
  props: {
    type: {
      required: true,
      type: String
    },
    content: {
      required: true,
      type: Array
    }
  },
  components: {
    ArticleHeader, ArticleSection, ArticleQuote
  },
  methods: {
    isItemBlock (name, val) {
      return name === val
    }
  }
}
</script>
